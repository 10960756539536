// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---content-posts-00-template-index-mdx": () => import("./../../../content/posts/00_template/index.mdx" /* webpackChunkName: "component---content-posts-00-template-index-mdx" */),
  "component---content-posts-01-why-start-a-blog-index-mdx": () => import("./../../../content/posts/01_why_start_a_blog/index.mdx" /* webpackChunkName: "component---content-posts-01-why-start-a-blog-index-mdx" */),
  "component---content-posts-02-committing-to-lunch-index-mdx": () => import("./../../../content/posts/02_committing_to_lunch/index.mdx" /* webpackChunkName: "component---content-posts-02-committing-to-lunch-index-mdx" */),
  "component---content-posts-03-optimal-compounding-with-penalties-index-mdx": () => import("./../../../content/posts/03_optimal_compounding_with_penalties/index.mdx" /* webpackChunkName: "component---content-posts-03-optimal-compounding-with-penalties-index-mdx" */),
  "component---content-posts-04-thoughts-on-emacs-conf-2021-index-mdx": () => import("./../../../content/posts/04_thoughts_on_emacs_conf_2021/index.mdx" /* webpackChunkName: "component---content-posts-04-thoughts-on-emacs-conf-2021-index-mdx" */),
  "component---content-posts-05-ip-changing-made-easy-index-mdx": () => import("./../../../content/posts/05_ip_changing_made_easy/index.mdx" /* webpackChunkName: "component---content-posts-05-ip-changing-made-easy-index-mdx" */),
  "component---content-posts-06-solving-the-two-stocks-probability-puzzle-index-mdx": () => import("./../../../content/posts/06_solving_the_two_stocks_probability_puzzle/index.mdx" /* webpackChunkName: "component---content-posts-06-solving-the-two-stocks-probability-puzzle-index-mdx" */),
  "component---content-posts-07-this-post-mines-crypto-index-mdx": () => import("./../../../content/posts/07_this_post_mines_crypto/index.mdx" /* webpackChunkName: "component---content-posts-07-this-post-mines-crypto-index-mdx" */),
  "component---content-posts-08-the-primitive-root-theorem-index-mdx": () => import("./../../../content/posts/08_the_primitive_root_theorem/index.mdx" /* webpackChunkName: "component---content-posts-08-the-primitive-root-theorem-index-mdx" */),
  "component---content-posts-09-implementing-kzg-10-polynomial-commitments-index-mdx": () => import("./../../../content/posts/09_implementing_kzg10_polynomial_commitments/index.mdx" /* webpackChunkName: "component---content-posts-09-implementing-kzg-10-polynomial-commitments-index-mdx" */),
  "component---content-posts-10-writing-a-weaver-solver-index-mdx": () => import("./../../../content/posts/10_writing_a_weaver_solver/index.mdx" /* webpackChunkName: "component---content-posts-10-writing-a-weaver-solver-index-mdx" */),
  "component---content-posts-11-how-to-host-your-own-browser-shell-with-v-86-index-mdx": () => import("./../../../content/posts/11_how_to_host_your_own_browser_shell_with_v86/index.mdx" /* webpackChunkName: "component---content-posts-11-how-to-host-your-own-browser-shell-with-v-86-index-mdx" */),
  "component---content-posts-12-setting-up-ipad-screen-mirroring-on-linux-index-mdx": () => import("./../../../content/posts/12_setting_up_ipad_screen_mirroring_on_linux/index.mdx" /* webpackChunkName: "component---content-posts-12-setting-up-ipad-screen-mirroring-on-linux-index-mdx" */),
  "component---content-posts-13-shape-typing-numpy-arrays-index-mdx": () => import("./../../../content/posts/13_Shape_typing_numpy_arrays/index.mdx" /* webpackChunkName: "component---content-posts-13-shape-typing-numpy-arrays-index-mdx" */),
  "component---content-posts-15-lagranges-theorem-index-mdx": () => import("./../../../content/posts/15_lagranges_theorem/index.mdx" /* webpackChunkName: "component---content-posts-15-lagranges-theorem-index-mdx" */),
  "component---content-posts-16-solving-the-rainbow-hats-puzzle-index-mdx": () => import("./../../../content/posts/16_solving_the_rainbow_hats_puzzle/index.mdx" /* webpackChunkName: "component---content-posts-16-solving-the-rainbow-hats-puzzle-index-mdx" */),
  "component---src-templates-404-template-tsx": () => import("./../../../src/templates/404.template.tsx" /* webpackChunkName: "component---src-templates-404-template-tsx" */),
  "component---src-templates-about-template-tsx": () => import("./../../../src/templates/about.template.tsx" /* webpackChunkName: "component---src-templates-about-template-tsx" */),
  "component---src-templates-home-template-tsx": () => import("./../../../src/templates/home.template.tsx" /* webpackChunkName: "component---src-templates-home-template-tsx" */)
}

